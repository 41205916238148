import TemplateContents from './TemplateContents';

import Top from './Top';

export default class extends TemplateContents {
    constructor(param) {
        super(param);

        this.pack.common = this;
    }

    init() {
        super.init();
    }

    addScrollTarget(target) {
        this.scrollTarget = target;
    }

    removeScrollTarget() {
        this.scrollTarget = undefined;
    }

    addEnterframeTarget(target) {
        this.enterframeTarget = target;
    }

    removeEnterframeTarget() {
        this.enterframeTarget = undefined;
    }

    setVars() {
        super.setVars();
        this.enterframeID;
        this.enterframeIgnoreCnt = 0;

        this.pastPageYOffset = 0;
        this.scrollYOffset = 0;
        this.pastScrollYOffset = 0;


        this.UPDATE_LOAD_COEFF = 0.5;
        this.FPS = 30;
        this.targetInterval = 1000 / this.FPS;
        this.prevTime = Date.now() - this.targetInterval;
    }

    setDom() {
        super.setDom();
        this.scrollWrapper = document.querySelector('.scroll-wrapper');
        this.barbaContainer = document.querySelector('.barba-container');
    }

    initEvents() {
        super.initEvents();

        this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/) ? 'orientationchange' : 'resize';

        window.addEventListener("load", this.loadedHandler.bind(this));
        window.addEventListener("DOMContentLoaded", this.DOMContentloadedHandler.bind(this));

        window.addEventListener(this.resizeEvent, this.resizeHandler.bind(this), false);
        window.addEventListener('scroll', this.scrollHandler.bind(this), {passive: false});


        this.resizeHandler();
        this.enterframe();
    }

    DOMContentloadedHandler(event) {
        this.initContainer();
    }

    loadedHandler(event) {

    }

    initContainer() {
        window.scrollTo(0, 0);


        let namespace = this.barbaContainer.dataset.namespace;
        this.pack.current = namespace;

        if (namespace === 'top') {
            this.pack.top = new Top(this.pack);
            this.pack.top.start();
        }

        this.setCurrent();
    }

    setCurrent() {
        // this.pack.header.setCurrent();
    }

    start() {

    }

    scrollHandler() {

    }


    enterframe() {
        this.enterframeID = window.requestAnimationFrame(this.enterframe.bind(this));

        if (this.enterframeTarget && this.enterframeTarget.enterframe) this.enterframeTarget.enterframe();


        let currentTime = Date.now();
        let updated = false;
        while (currentTime - this.prevTime > this.targetInterval) {

            updated = true;
            this.prevTime += this.targetInterval;
            const now = Date.now();
            const updateTime = now - currentTime;
            if (updateTime > this.targetInterval * this.UPDATE_LOAD_COEFF) {
                // overloaded
                if (this.prevTime < now - this.targetInterval) {
                    // do not accumulate too much
                    this.prevTime = now - this.targetInterval;
                }
                break;
            }
        }

        if (updated) {
            //30fpsで処理
            this.enterframeThinOut();
            if (this.enterframeTarget) this.enterframeTarget.enterframeThinOut();
        }

        if (this.enterframeIgnoreCnt % 90 === 0) {
            //間引き処理(1 execution per 90 frames)
            this.resizeHandler();
        }

        //前回のスクロール位置（全ての処理が終わってから代入）
        this.pastPageYOffset = this.pack.pastPageYOffset = this.pack.pageYOffset;

        this.enterframeIgnoreCnt++;
    }

    enterframeThinOut() {

    }

    executeResize() {
        super.executeResize();
    }
}