import TemplateContents from './TemplateContents';

export default class extends TemplateContents {
    constructor(param) {
        super(param);
    }

    init() {
        super.init();


        this.initObjects();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset() {
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct() {
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars() {
        super.setVars();

        this.current = this.past = 0;
        this.topObjPoints = [];
        this.topObjTrianglePoints = [];
        this.topObjCirclePoints = [];
        this.topObjPointsLen = 36;
        this.isTopObjCircle = false;

        this.CANVAS_W = 615;
        this.CANVAS_H = 615;
        this.CANVAS_W_H = this.CANVAS_W / 2;
        this.CANVAS_H_H = this.CANVAS_H / 2;

        this.TRIANGLE_H = 532;
        this.TRIANGLE_H_HALF = this.TRIANGLE_H / 2;

        this.TRIANGLE_TOP_Y = (this.CANVAS_H - this.TRIANGLE_H) / 2;
        this.TRIANGLE_BOTTOM_Y = this.CANVAS_H - this.TRIANGLE_TOP_Y;

        this.TRIANGLE_SINGLE_W = this.CANVAS_W_H / 12;
        this.TRIANGLE_SINGLE_W2 = this.CANVAS_W / 12;
        this.TRIANGLE_SINGLE_H = this.TRIANGLE_H / 12;

        this.CIRCLE_SINGLE_DEG = 360 / this.topObjPointsLen;
        this.CIRCLE_RADIUS = 280;

        for( let i = 0, len = this.topObjPointsLen; i < len; i++ ){
            let x, y;
            if(i <= 12){
                x = this.CANVAS_W_H - (this.TRIANGLE_SINGLE_W * i);
                y = this.TRIANGLE_TOP_Y + (this.TRIANGLE_SINGLE_H * i);
            }else if(i >= 13 && i <= 24){
                let id = i - 12;
                x = this.TRIANGLE_SINGLE_W2 * id;
                y = this.TRIANGLE_BOTTOM_Y;
            }else{
                let id = i - 24;
                x = this.CANVAS_W - this.TRIANGLE_SINGLE_W * id;
                y = this.TRIANGLE_BOTTOM_Y - (this.TRIANGLE_SINGLE_H * id);
            }

            this.topObjTrianglePoints.push({x:x, y:y});
            this.topObjPoints.push({x:x, y:y});
        }

        for( let i = 0, len = this.topObjPointsLen; i < len; i++ ){
            let deg = 270 - this.CIRCLE_SINGLE_DEG * i;

            let x = this.CANVAS_W_H + Math.cos(this.pack.d2r(deg)) * this.CIRCLE_RADIUS;
            let y = this.CANVAS_H_H + Math.sin(this.pack.d2r(deg)) * this.CIRCLE_RADIUS;

            this.topObjCirclePoints.push({x:x, y:y});
        }

        this.triangleScale = 1;
        this.circleScale = 0;

    }

    setDom() {
        super.setDom();

        this.container = document.querySelector('main.top .object_container');
        this.topObjDom = document.querySelector('main.top .object_container .top_obj');
        this.topObjPath = document.querySelector('main.top .object_container .top_obj svg path');
    }

    initEvents() {
        super.initEvents();
    }

    initObjects(){

    }

    start() {

    }

    scrollHandler() {

    }

    change(id){
        if (id === this.current) return;
        this.past = this.current;
        this.current = id;
        let dr = 1;

        if(id === 0){
            this.container.classList.remove('corporate');
            for( let i = 0, len = this.topObjPoints.length; i < len; i++ ){
                let obj = this.topObjPoints[i];
                let trianglePoint = this.topObjTrianglePoints[i];
                TweenMax.to(obj, dr, {x:trianglePoint.x, y:trianglePoint.y, ease:Quart.easeInOut});
            }
            this.isTopObjCircle = false;
        }else if(id === 1){
            this.container.classList.add('corporate');
            for( let i = 0, len = this.topObjPoints.length; i < len; i++ ){
                let obj = this.topObjPoints[i];
                let circlePoint = this.topObjCirclePoints[i];
                TweenMax.to(obj, dr, {x:circlePoint.x, y:circlePoint.y, ease:Quart.easeInOut});
            }

            TweenMax.delayedCall(dr, ()=>{
                this.isTopObjCircle = true;
            });
        }
    }

    render(){
        this.renderTopObj();
    }

    renderTopObj() {
        let src = '';

        for( let i = 0, len = this.topObjPoints.length; i < len; i++ ){
            let obj = this.topObjPoints[i];
            if(i === 0) src += 'M' + obj.x + ' ' + obj.y;
            else src += ' L' + obj.x + ' ' + obj.y;
        }

        src + ' Z';

        if(this.isTopObjCircle){
           // src = 'M 3, 58 A 55 55 0 1 0 113 58 A 55 55 0 1 0 3 58';
           src = 'M 588,308 a 280 280 0 1 1 0,-1';
        }

        this.topObjPath.setAttribute("d", src);

    }

    enterframe() {

    }

    enterframeThinOut() {
        this.render();
    }

    executeResize() {
        super.executeResize();
    }
}