import TemplateContents from './TemplateContents';
// import TopHero from './TopHero';
import TopObjects from './TopObjects';


export default class extends TemplateContents {
    constructor(param) {
        super(param);
    }

    init() {
        super.init();

        // this.pack.top_hero = this.hero = new TopHero(this.pack);
        this.pack.top_objects = this.objects = new TopObjects(this.pack);
        // this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    reset() {
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    destruct() {
        super.destruct();

        // this.hero.destruct();
        this.objects.destruct();

        // this.pack.common.removeScrollTarget(this);
        this.pack.common.removeEnterframeTarget(this);
    }

    setVars() {
        super.setVars();
        this.lockScroll = true;
        this.startTouchStartY = 0;

        this.currentContents = 0;
        this.contentsLen = 5;
        this.enabledScroll = true;
        this.worksScroll = 0;

        this.prevWheelTime = Date.now();
        this.prevWheelDelta = 0;
    }

    setDom() {
        super.setDom();

        this.headerHeading = document.querySelector('.header__heading');
        this.currentDOM = document.querySelector('main.top .current');
        this.currentULDOM = document.querySelector('main.top .current ul');
        this.section4 = document.querySelector('.section4');
        this.entryBtn = document.querySelector('main.top .entry_btn');
        this.currentDOM = document.querySelector('main.top .current');
    }

    initEvents() {
        super.initEvents();

        this.mousewheelEvent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
        this.bindWheelHandler = this.wheelHandler.bind(this);
        this.bindTouchStartHandler = this.touchStartHandler.bind(this);
        this.bindTouchMoveHandler = this.touchMoveHandler.bind(this);
        this.bindTouchEndHandler = this.touchEndHandler.bind(this);

        document.addEventListener(this.mousewheelEvent, this.bindWheelHandler, {passive: false});
        document.addEventListener('touchstart', this.bindTouchStartHandler, {passive: false});
        document.addEventListener('touchmove', this.bindTouchMoveHandler, {passive: false});
        document.addEventListener('touchend', this.bindTouchEndHandler, {passive: false});
    }

    onScrollEnd() {
        this.enabledScroll = true;
        if (this.currentContents === 4) this.lockScroll = false;
    }

    wheelHandler(event) {
        if (!event) event = window.event; //for legacy IE
        let delta = event.deltaY ? (event.deltaY) : event.wheelDelta ? event.wheelDelta : -(event.detail);

        let currentTime = Date.now();
        let currentDelta = Math.abs(delta);
        let isCancel = false;

        if(currentDelta <= this.prevWheelDelta){
            if(currentTime - this.prevWheelTime < 100 ) isCancel = true;
        }

        this.prevWheelTime = currentTime;
        this.prevWheelDelta = currentDelta;

        if (this.lockScroll) {
            event.preventDefault();

            if (!this.enabledScroll) return;

            if(this.currentContents === 2) {
                this.worksScroll += delta;

                if(this.worksScroll < 0) {
                    this.worksScroll = 0;
                    this.backwardScroll();
                }else if(this.worksScroll > this.sw * 4.5) {
                    this.worksScroll = this.sw * 4.5;
                    this.forwardScroll();
                }else{
                    // this.hero.updateWorks(this.worksScroll);
                }

                return;
            }

            if(isCancel){
                event.preventDefault();
                return;
            }
            
            if (delta > 0) {
                this.forwardScroll();
            } else {
                this.backwardScroll();
            }
        }
    }

    touchStartHandler(event) {
        let touch = event.changedTouches[0];
        this.startTouchStartY = touch.clientY;
    }


    touchMoveHandler(event) {
        if (this.lockScroll) {
            event.preventDefault();
        }
    }

    touchEndHandler(event) {
        if (this.lockScroll) {
            // event.preventDefault();

            let touch = event.changedTouches[0];

            if (touch.clientY < this.startTouchStartY) {
                this.forwardScroll();
            } else {
                this.backwardScroll();
            }
        }
    }

    forwardScroll() {
        this.enabledScroll = false;
        this.currentContents++;
        if (this.currentContents > this.contentsLen - 1) this.currentContents = this.contentsLen - 1;
        this.pack.anchorScroll(this.sh * this.currentContents, 1, Quart.easeInOut, this.onScrollEnd, this);
        // this.hero.change(this.currentContents);
        this.objects.change(this.currentContents);
        this.changeHeader(true);
    }

    backwardScroll() {
        this.enabledScroll = false;
        this.currentContents--;
        if (this.currentContents < 0) this.currentContents = 0;
        this.pack.anchorScroll(this.sh * this.currentContents, 1, Quart.easeInOut, this.onScrollEnd, this);
        // this.hero.change(this.currentContents);
        this.objects.change(this.currentContents);
        this.changeHeader(false);
    }

    changeHeader(isForward){
        if(this.currentContents === 1 && isForward){
            this.headerHeading.classList.add("hide");
            this.currentDOM.classList.add("show");
        }else if(this.currentContents === 0 && !isForward){
            this.headerHeading.classList.remove("hide");
            this.currentDOM.classList.remove("show");
        }else{
            for( let i = 1, len = 4; i <= len; i++ ){
                this.currentULDOM.classList.remove("patern" + i);
            }
            this.currentULDOM.classList.add("patern" + this.currentContents);
        }

        if(this.currentContents === 4){
            this.currentDOM.classList.add("white");
            this.entryBtn.classList.add("white");
        }else{
            this.currentDOM.classList.remove("white");
            this.entryBtn.classList.remove("white");
        }
    }

    start() {

        //initialize scroll position
        TweenMax.delayedCall(1, () => {
            window.scrollTo(0, 0);
        });
    }

    scrollHandler() {

    }


    enterframe() {

    }

    enterframeThinOut() {
        // this.hero.enterframeThinOut();
        this.objects.enterframeThinOut();

        if (!this.lockScroll) {
            let top = this.section4.getBoundingClientRect().top;
            if (top > 0) {
                this.lockScroll = true;
            }
        }
    }

    executeResize() {
        super.executeResize();
    }
}