import "@babel/polyfill";

import Config from "./Config";

require('./libs/TweenMax.min.js');
require('./libs/daijima/trace.js');
require('./libs/daijima/requestanimationframe.js');
// require('./barba-custom.js');

let packName = "IVG_RECRUIT";
let pack = new Config(window, packName);

import "../scss/common.scss";
import "../scss/top.scss";
//CSS Modulesとして使用する場合
// import * as styles from "../scss/common.scss";

import Common from './Common';

new Common(pack);